import React from "react";
import { css, keyframes } from "@emotion/core";
// import { publicContext } from "gatsby-plugin-transition-link/context/createTransitionContext";
import { colors, mediaQueries } from "../../configs/_configSite";
import TextBlinker24 from "../Base/text/TextBlinker24";

const buttonMove = keyframes`
  0% {
    transform: translateY(0px);  
  }
  80% {
    transform: translateY(25px);  
  }
  100% {
    transform: translateY(25px);  
  }
`;

const buttonMoveMobile = keyframes`
  0% {
    transform: translateY(28px);  
  }
  80% {
    transform: translateY(5px);  
  }
  100% {
    transform: translateY(5px);  
  }
`;

const ScrollIndicator = () => {
  return (
    <div
      className="scroll-indicator-container"
      css={css`
        position: absolute;
        z-index: 1;
        opacity: 1;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: 45px;
        height: 45px;
        width: 150px;
        text-align: center;
      `}
    >
      <TextBlinker24
        css={css`
          position: absolute;
          display: block;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
          transform: translateY(-30px);
          font-weight: 500;
          font-size: 0.875rem;
          color: ${colors.white};
          text-transform: uppercase;

          @media (max-width: ${mediaQueries.md}px) {
            display: none;
          }
        `}
      >
        Scroll To Discover
      </TextBlinker24>
      <TextBlinker24
        css={css`
          position: absolute;
          display: none;
          left: 0;
          right: 0;
          margin: 0 auto;
          text-align: center;
          transform: translateY(-30px);
          font-weight: 500;
          font-size: 0.875rem;
          color: ${colors.white};
          text-transform: uppercase;

          @media (max-width: ${mediaQueries.md}px) {
            display: block;
          }
        `}
      >
        Swipe To Discover
      </TextBlinker24>

      <div
        css={css`
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 2px;
          height: 100%;
          background: ${colors.gold};

          @media (max-width: ${mediaQueries.md}px) {
            width: 25px;
            border: 2px solid ${colors.gold};
            background: none;
            border-radius: 6px;
            height: 40px;
          }
        `}
      />
      <div
        className="scroll-indicator-wheel"
        css={css`
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          top: 0;
          height: 16px;
          width: 6px;
          background: ${colors.gold};
          animation: ${buttonMove} 1.5s ease-in-out infinite;

          @media (max-width: ${mediaQueries.md}px) {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            animation: ${buttonMoveMobile} 1.5s ease-in-out infinite;
          }
        `}
      />
    </div>
  );
};

export default ScrollIndicator;
