import React from "react";
import styled from "@emotion/styled";
import { css, keyframes } from "@emotion/core";

// Configs
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { mediaQueries, colors, socials } from "../../configs/_configSite";

// Components
import FooterMain from "../Footer/FooterMain";
import TitleMain from "../Base/titles/TitleMain";
import TextBlinker24 from "../Base/text/TextBlinker24";

// Images
import ImgFeatureGoldLeaf from "../Images/Features/ImgFeatureGoldLeaf";
import ImgBackground from "../../images/backgrounds/anana-vestige-background-raindrops.png";
import ImgFeatureDarkLeaves from "../Images/Features/ImgFeatureDarkLeaves";

// Animations
const movingBackground = keyframes`
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 9999px;
  }
`;

const ContainerWrapper = styled.div`
  background: linear-gradient(180deg, #dddedf 0%, #f6f6f6 100%);
`;

// Styled Components
const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100vw;
  height: calc(100vh - 90px);
  padding: 20vh 0;
  box-sizing: border-box;

  background: url(${ImgBackground}) repeat;
  animation: ${movingBackground} 300s linear infinite;

  @media (max-width: ${mediaQueries.md}px) {
    height: calc(100vh - 110px);
  }
  @media (max-width: ${mediaQueries.xs}px) {
    justify-content: center;
  }
`;

const SocialContainer = styled.section`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1440px;
  margin-bottom: 60px;

  @media (max-width: ${mediaQueries.lg}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
  @media (max-width: ${mediaQueries.md}px) {
    margin-bottom: 0;
  }
`;

const SocialLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 0 100%;
  max-width: 530px;
  text-decoration: none;
  flex-direction: row;

  transition: 0.2s;

  &:hover {
    opacity: 0.5;
  }

  @media (max-width: ${mediaQueries.lg}px) {
    margin: 0 auto;
  }
`;

const SocialTitleMain = styled(TitleMain)`
  width: 100%;
  color: ${colors.gold};
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: -30px;

  @media (max-width: ${mediaQueries.lg}px) {
    font-size: 2rem;
    margin-bottom: -100px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    font-size: 1.5rem;
    margin-bottom: -35px;
  }
`;

const SocialGoldLine = styled.div`
  height: 1px;
  width: 330px;
  margin-left: 5px;
  margin-right: 30px;
  margin-bottom: 5px;
  background: ${colors.gold};

  @media (max-width: ${mediaQueries.md}px) {
    width: 350px;
    margin-right: 10px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    width: 270px;
  }
`;

const SocialLeafContainer = styled.div`
  width: 90px;
  transform: rotate(45deg);
  @media (max-width: ${mediaQueries.md}px) {
    width: 75px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    width: 40px;
  }
`;

const Title = styled(TextBlinker24)`
  text-transform: uppercase;
  line-height: 1em;
  text-align: center;

  @media (max-width: ${mediaQueries.md}px) {
    line-height: 1em;
    margin: 0;
    font-size: 1em;
  }
`;

export default () => (
  <ContainerWrapper>
    <Container>
      <ImgFeatureDarkLeaves cssProp="position: absolute !important; bottom: 0; width: 100%;" />
      <div>
        <Title
          css={css`
            font-size: 3em;

            @media (max-width: ${mediaQueries.md}px) {
              font-size: 2.5em;
            }
            @media (max-width: ${mediaQueries.xs}px) {
              font-size: 1.5em;
            }
          `}
        >
          For The Latest News And Updates
        </Title>
        <Title
          css={css`
            font-size: 2em;
            @media (max-width: ${mediaQueries.md}px) {
              font-size: 1.5em;
            }
            @media (max-width: ${mediaQueries.xs}px) {
              font-size: 1em;
              margin-bottom: 90px;
            }
          `}
        >
          Stay Connected On Our Socials
        </Title>
      </div>

      <SocialContainer>
        <SocialLink href={socials.facebook} target="_blank">
          <SocialTitleMain>Join Our Facebook!</SocialTitleMain>
          <SocialLeafContainer>
            <ImgFeatureGoldLeaf />
          </SocialLeafContainer>
          <SocialGoldLine />
          <FaFacebookF
            css={css`
              height: 75px;
              width: 75px;
              fill: ${colors.gold};
              @media (max-width: ${mediaQueries.md}px) {
                height: 50px;
                width: 50px;
              }
              @media (max-width: ${mediaQueries.xs}px) {
                height: 30px;
                width: 30px;
              }
            `}
          />
        </SocialLink>

        <SocialLink href={socials.instagram} target="_blank">
          <SocialTitleMain>Check out Instagram!</SocialTitleMain>
          <SocialLeafContainer>
            <ImgFeatureGoldLeaf />
          </SocialLeafContainer>
          <SocialGoldLine />
          <FaInstagram
            css={css`
              height: 75px;
              width: 75px;
              fill: ${colors.gold};
              @media (max-width: ${mediaQueries.md}px) {
                height: 50px;
                width: 50px;
              }
              @media (max-width: ${mediaQueries.xs}px) {
                height: 30px;
                width: 30px;
              }
            `}
          />
        </SocialLink>
      </SocialContainer>
    </Container>
    <FooterMain />
  </ContainerWrapper>
);

// Masthead.defaultProps = {
//   progress: 0
// };

// Masthead.propTypes = {
//   progress: PropTypes.number
// };

// export default Masthead;
