import React, { useState } from "react";
import gsap from "gsap";
import { css } from "@emotion/core";
import { useSpring, animated } from "react-spring";

// Components
import styled from "@emotion/styled";
import Container from "../Layouts/Container";
import TitleMain from "../Base/titles/TitleMain";
import TextMain from "../Base/text/TextMain";
import ToggleTabs from "../Toggles/ToggleTabs";
import ToggleImageTab from "../Toggles/ToggleTabImage";

// Images
import ImgContentToggle1 from "../Images/Home/content-toggle/ImgContentToggle1";
import ImgContentToggle2 from "../Images/Home/content-toggle/ImgContentToggle2";
import ImgContentToggle3 from "../Images/Home/content-toggle/ImgContentToggle3";
import ImgContentToggleThumb1 from "../Images/Home/content-toggle-thumbs/ImgContentToggleThumb1";
import ImgContentToggleThumb2 from "../Images/Home/content-toggle-thumbs/ImgContentToggleThumb2";
import ImgContentToggleThumb3 from "../Images/Home/content-toggle-thumbs/ImgContentToggleThumb3";
import ImgFeatureGoldCrown from "../Images/Features/ImgFeatureGoldCrown";

import { mediaQueries } from "../../configs/_configSite";

// Style
const Background = styled(animated.div)`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  opacity: 0;
`;

const Wrapper = styled.section`
  z-index: 10;
  position: relative;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 100%;
  width: 100%;

  max-width: 1440px;
  margin: 0 auto;

  @media (max-width: ${mediaQueries.md}px) {
    grid-template-rows: 1fr auto;
  }
`;

const CrownContainer = styled.div`
  width: 150px;
  margin: 0 auto;
  @media (max-width: ${mediaQueries.md}px) {
    width: 60px;
  }
`;

const Content = styled.section`
  position: relative;
  top: 8vh;
  grid-column: 5 / span 4;

  @media (max-width: ${mediaQueries.lg}px) {
    grid-column: 4 / span 6;
  }

  @media (max-width: ${mediaQueries.md}px) {
    grid-column: 3 / span 8;
  }

  @media (max-width: ${mediaQueries.xs}px) {
    grid-column: 1 / span 12;
  }
`;

const SidebarActions = styled.div`
  grid-column: 10 / span 3;
  align-self: center;

  @media (max-width: ${mediaQueries.md}px) {
    padding: 0 30px;
    grid-column: 3 / span 8;
  }
`;

// Data
const data = [
  {
    title: "Wear Your Crown",
    thumbTitle: "Your Crown",
    imageBackground: <ImgContentToggle1 />,
    content: [
      `We get it... It’s hard sometimes to feel and embrace the truth
      that we are valued and made to shine with eachother by using the
      gifts we have from a walk thats uniquely our own. We need to
      remember from where that vestige of glory comes, and come together
      whenever we see it fade. So be encouraged and let that light shine
      out in the world, wear your crown!`
    ],
    imageThumb: <ImgContentToggleThumb1 />,
    color: ""
  },
  {
    title: "Our Mission",
    thumbTitle: "Our Mission",
    imageBackground: <ImgContentToggle2 />,
    content: [
      `We are here to remind eachother of the amazing people we are! We are Valuable, We are Unique,  We are Made to Make a Difference. And You are a apart of that mission too... We all need to come alongside and help eachother see who we really are!`
    ],
    imageThumb: <ImgContentToggleThumb2 />,
    color: "#ffffff"
  },
  {
    title: "Everyday Reminders",
    thumbTitle: "The Brand",
    imageBackground: <ImgContentToggle3 />,
    content: [
      `We know and believe that you are worth infinitely more than anything we have to sell... we will activley tell you so... But sometimes even we need to be reminded of how special we trully are. Thats why each product, each post, each picture we share is meant to remind us of that forgotten worth and encourage eachother to great things! Is there someone you can encourage today?`
    ],
    imageThumb: <ImgContentToggleThumb3 />,
    color: ""
  }
];

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const trans1 = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0) scale(1.1)`;

export default () => {
  const [pops, set] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 2, tension: 250, friction: 140 }
  }));
  const [activeIndex, setActiveIndex] = useState(0);
  const [loading, setLoading] = useState(false);

  const clickSetIndex = index => {
    if (!loading) {
      gsap.to(`#content-toggle-background-${activeIndex}`, 1, {
        css: { opacity: 0 }
      });
      gsap.to(`#content-toggle-background-${index}`, 1, {
        delay: 0.5,
        css: { opacity: 1 }
      });
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
      setActiveIndex(index);
    }
  };

  return (
    <Container
      vCenter
      hCenter
      height="100vh"
      fluid
      background="#ffffff"
      cssProp="overflow: hidden;"
    >
      <Wrapper
        onMouseMove={({ clientX: x, clientY: y }) => set({ xy: calc(x, y) })}
      >
        <Content>
          <CrownContainer>
            <ImgFeatureGoldCrown />
          </CrownContainer>

          <TitleMain
            css={css`
              text-align: center;
              ${data[activeIndex].color !== ""
                ? `color: ${data[activeIndex].color}`
                : ""}
            `}
          >
            {data[activeIndex].title}
          </TitleMain>

          {data[activeIndex].content.map(
            item =>
              (typeof item === "string" && (
                <TextMain
                  key={item}
                  css={css`
                    max-width: 90%;
                    margin: 0 auto;
                    ${
                      data[activeIndex].color !== ""
                        ? `color: ${data[activeIndex].color};`
                        : ""
                    }
                      @media (max-width: ${mediaQueries.md}px) {
                        font-size: 1.25rem;
                      }
                  `}
                >
                  {item}
                </TextMain>
              )) ||
              (typeof item !== "string" && item)
          )}
        </Content>

        <SidebarActions>
          <ToggleTabs
            toggles={[
              data.map((item, i) => (
                <ToggleImageTab
                  key={item.title}
                  image={item.imageThumb}
                  text={item.thumbTitle}
                  onClick={clickSetIndex}
                  thisIndex={i}
                  activeIndex={activeIndex}
                />
              ))
            ]}
          />
        </SidebarActions>
      </Wrapper>

      {data.map((item, i) => (
        <Background
          key={item.title}
          style={{ transform: pops.xy.interpolate(trans1) }}
          id={`content-toggle-background-${i}`}
          className="content-toggle-background"
          css={css`
            opacity: ${i === 0 ? 1 : 0};
          `}
        >
          {item.imageBackground}
        </Background>
      ))}
    </Container>
  );
};
