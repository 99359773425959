import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

// Components
import VideoPLayerFullScreen from "../VideoPlayers/VideoPlayerFullScreen";
import TitleMain from "../Base/titles/TitleMain";
import TextAssistant24 from "../Base/text/TextAssistant24";

import VideoFile from "../../videos/test-video.mp4";

// Styled Components
const Container = styled.section`
  position: relative;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export default () => {
  return (
    <Container>
      <VideoPLayerFullScreen video={VideoFile} />
      <div
        css={css`
          z-index: 5;
          position: relative;
          width: 100%;
          max-width: 500px;
          padding: 0 30px;
        `}
      >
        <TitleMain
          css={css`
            text-align: center;
            color: #ffffff;
          `}
        >
          Are You Ready
        </TitleMain>
        <TextAssistant24
          css={css`
            font-weight: 500;
          `}
        >
          Let knowledge become belief and fill the very being of your soul. When
          we BELIEVE the truth of who we are and the destiny we have, we are an
          unwavering banner that will not bend to the voices that seek to tear
          us down. Believe and be secure in the life that is given for you.
        </TextAssistant24>
      </div>
    </Container>
  );
};
