import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

// Components
import ToggleImageTab from "./ToggleTabImage";

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const ToggleTabs = ({ toggles }) => {
  // const [activeToggle, setActiveToggle] = useState(false);

  return <Wrapper>{toggles}</Wrapper>;
};

ToggleTabs.defaultProps = {
  toggles: [<ToggleImageTab />, <ToggleImageTab />]
};

ToggleTabs.propTypes = {
  toggles: PropTypes.arrayOf(PropTypes.node)
};

export default ToggleTabs;
