/* eslint-disable react/prop-types */
import React, { useRef, useContext, useEffect } from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";

// Components
import Div100vh from "react-div-100vh";
import LeavesLeftOuter from "../LeavesBackground/LeavesLeftOuter";
import LeavesLeftInner from "../LeavesBackground/LeavesLeftInner";
import LeavesRightInner from "../LeavesBackground/LeavesRightInner";
import LeavesRightOuter from "../LeavesBackground/LeavesRightOuter";

// Contexts
import { ContextEntryUI } from "../../contexts/context-entry-ui";

// Images
import ImgLogoGoldText from "../Images/Logos/ImgLogoGoldText";
import ImgQuoteGold from "../Images/Logos/ImgQuoteGold";
import { mediaQueries } from "../../configs/_configSite";
import ScrollIndicator from "../Animations/ScrollIndicator";

const Container = styled(Div100vh)`
  z-index: 2;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  transition: 1s;
`;

const Line = styled.div`
  width: ${mediaQueries.lg}px;
  height: 1px;
  margin: 15px 0;
  background: #ffffff;

  @media (max-width: ${mediaQueries.lg}px) {
    width: ${mediaQueries.md}px;
  }
  @media (max-width: ${mediaQueries.md}px) {
    width: ${mediaQueries.sm}px;
  }
  @media (max-width: ${mediaQueries.sm}px) {
    width: ${mediaQueries.xs}px;
  }
  @media (max-width: ${mediaQueries.xs}px) {
    width: 90%;
  }
`;

const FeatureLeavesEntry = () => {
  const {
    entryClosed,
    setEntryClosed,
    readyToSlideDown,
    slidesAbove
  } = useContext(ContextEntryUI);
  const refLeftOuter = useRef();
  const refLeftInner = useRef();
  const refRightOuter = useRef();
  const refRightInner = useRef();

  const scroll = e => {
    const scale = 1;
    let number = 0;

    // if scrolling down
    if (e.deltaY > 0) {
      number = 1800;
      setEntryClosed(false);
      setTimeout(() => {
        readyToSlideDown.current = true;
      }, 1000);
    } else {
      if (slidesAbove.current) return;
      readyToSlideDown.current = false;
      setEntryClosed(true);
    }
    refLeftOuter.current.style.transform = `scale(${scale}) translateX(-${number /
      3}px)`;
    refLeftInner.current.style.transform = `scale(${scale}) translateX(-${number}px)`;
    refRightInner.current.style.transform = `scale(${scale}) translateX(${number}px)`;
    refRightOuter.current.style.transform = `scale(${scale}) translateX(${number /
      3}px)`;
  };

  let start = 0;
  const startScroll = e => {
    start = e.touches[0].clientY;
  };
  const moveScroll = e => {
    const moved = e.changedTouches[0].clientY;
    scroll({ deltaY: start > moved ? 1 : -1 });
  };
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("wheel", scroll);
      window.addEventListener("touchstart", startScroll);
      window.addEventListener("touchmove", moveScroll);
    }

    return () => {
      window.removeEventListener("touchstart", startScroll);
      window.removeEventListener("touchmove", moveScroll);
      window.removeEventListener("wheel", scroll);
    };
  }, []);

  return (
    <>
      <LeavesRightInner propRef={refRightInner} />
      <LeavesLeftInner propRef={refLeftInner} />
      <LeavesLeftOuter propRef={refLeftOuter} />
      <LeavesRightOuter propRef={refRightOuter} />
      <Container
        css={css`
          opacity: ${entryClosed ? 1 : 0};
          pointer-events: none;
        `}
      >
        <ImgLogoGoldText
          cssProp={`
            position: relative;
            width: calc(30% + 200px);
          `}
        />
        <Line />
        <ImgQuoteGold
          cssProp={`
            position: relative;
            width: 450px;
            width: calc(17.5% + 100px);
          `}
        />
        <ScrollIndicator />
      </Container>
    </>
  );
};

export default FeatureLeavesEntry;
