import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import Div100vh from "react-div-100vh";
import ImgBackgroundInTheMountains from "../../images/_home/carousel/anana-vestige-home-sitting-on-mountain.png";
import ImgBackgroundInThePark from "../../images/_home/carousel/anana-vestige-home-sitting-in-park.png";
import ImgBackgroundInTheSun from "../../images/_home/carousel/anana-vestige-home-woman-in-sun.png";
import TitleMain from "../Base/titles/TitleMain";
import { colors, mediaQueries } from "../../configs/_configSite";
import TextAssistant24 from "../Base/text/TextAssistant24";

const Container = styled(Div100vh)`
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: row;
  @media (max-width: ${mediaQueries.md}px) {
    flex-direction: column;
  }
`;

const ColumnHidden = styled.section`
  position: absolute;
  top: -100vh;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);

  transition: 1s ease-in-out;
`;

const TitleMainVisible = styled(TitleMain)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  padding-bottom: 60px;
  color: #000000;
  transition: 1s ease-in-out;

  color: #fff;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.7);
  @media (max-width: ${mediaQueries.md}px) {
    height: 100%;
    padding-bottom: 0px;
    color: #fff;
  }
`;

const Column = styled.section`
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: 1s ease-in-out;
  width: 33.33333vw;
  align-item: flex-start;

  &:hover {
    ${ColumnHidden} {
      top: 0;
    }
  }

  @media (max-width: ${mediaQueries.md}px) {
    align-items: center;
    min-height: 15vh;
    flex: 1;
    overflow: hidden;
    width: 100vw;

    &:hover {
      flex: 10;
      ${TitleMainVisible} {
        opacity: 0;
      }
    }
  }
`;

const TitleMainHidden = styled(TitleMain)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  padding-bottom: 60px;

  background: linear-gradient(
    170deg,
    #ffffff 0%,
    #ffffff 60%,
    transparent calc(60% + 2px)
  );
  color: ${colors.gold};
  text-align: center;

  @media (max-width: ${mediaQueries.md}px) {
    height: 200px;
    padding-bottom: 60px;
  }
`;

const TextBody = styled(TextAssistant24)`
  text-align: justify;
  color: #000000;
  padding: 0 10%;
`;

const data = [
  {
    image: ImgBackgroundInThePark,
    title: "Know",
    text: ` Do you KNOW that you are valuable? And that you are given a glorious
            purpose to share your gifts and bless those around you! We are here
            to remind everyone of these truths, and encourage one another to
            take joy from whom it comes!`
  },
  {
    image: ImgBackgroundInTheSun,
    title: "Believe",
    text: `We are all meant to Fulfill a great work. Whether by raising your
            kids well, finishing that degree, helping a neighboor, or even in an
            untold act of kidness, or an encouraging word. No matter how broken
            our journey has been, We can fulfill who we are meant to be by the
            grace of the one who gives us strength.`
  },
  {
    image: ImgBackgroundInTheMountains,
    title: "FulFill",
    text: `Let knowledge become belief and fill the very being of your soul.
            When we believe the truth of who we are and the destiny we have, we
            are an unwavering banner that will not bend to the voices that seek
            to tear us down. Believe and be secure in the life that is given for
            you.`
  }
];

export default () => {
  return (
    <Container>
      {data.map(item => (
        <Column
          key={item.title}
          css={css`background-image: url('${item.image}');`}
        >
          <TitleMainVisible>{item.title}</TitleMainVisible>
          <ColumnHidden>
            <TitleMainHidden>{item.title}</TitleMainHidden>
            <TextBody>{item.text}</TextBody>
          </ColumnHidden>
        </Column>
      ))}
    </Container>
  );
};
