import styled from "@emotion/styled";
import React from "react";
import PropTypes from "prop-types";
import { css } from "@emotion/core";
import { mediaQueries } from "../../configs/_configSite";

// Components
import TitleSmall from "../Base/titles/TitleSmall";

const Button = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  border: none;
  outline: none;
  height: 150px;
  width: 100%;
  overflow: hidden;
  background: none;
  padding: 0;
  margin: 0;

  cursor: pointer;

  @media (max-width: ${mediaQueries.md}px) {
    height: 120px;
  }

  @media (max-width: ${mediaQueries.md}px) {
    height: 100px;
    width: 49.99%;
    transform: translateY(-15px);
  }
`;

const Background = styled.section`
  position: absolute;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  transition: 0.5s;
  overflow: hidden;
`;

const ToggleImageTab = ({ image, text, onClick, thisIndex, activeIndex }) => {
  return (
    <Button
      onClick={() => {
        onClick(thisIndex);
      }}
    >
      <Background
        css={css`
          width: ${thisIndex === activeIndex ? "100%" : "50%"};
          @media (max-width: ${mediaQueries.md}px) {
            width: 100%;
            filter: grayscale(${thisIndex === activeIndex ? "0%" : "100%"});
          }
        `}
      >
        {image}
      </Background>
      <TitleSmall
        css={css`
          z-index: 1;
          grid-column: 2;
          top: 0;
          left: 0;
          text-align: center;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
        `}
      >
        {text}
      </TitleSmall>
    </Button>
  );
};

ToggleImageTab.defaultProps = {
  image: <img alt="placeholder" src="https://via.placeholder.com/468x150" />,
  thisIndex: 0,
  activeIndex: 0,
  text: "Click Here",
  onClick: () => {}
};

ToggleImageTab.propTypes = {
  image: PropTypes.node,
  thisIndex: PropTypes.number,
  activeIndex: PropTypes.number,
  text: PropTypes.string,
  onClick: PropTypes.func
};

export default ToggleImageTab;
